import React from 'react';
import Account from '../../components/account/index';
import Footer from '../../components/layout/footer';
import { useLocation } from 'react-router-dom';

function AccountContainer() {
  const location = useLocation();

  const params = new URLSearchParams(location?.search);
  const c = params.get('c');

  return (
    <div>
      <Account c={c} />
      <Footer />
    </div>
  );
}

export default AccountContainer;
