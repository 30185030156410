import { takeLatest, all } from 'redux-saga/effects';

/* ----------------- Types ----------------- */

import * as types from '../actions';

/* ----------------- Sagas ----------------- */

import { createAccount } from './accountSaga';

/* ----------------- API ----------------- */

function* accountActionWatcher() {
  yield takeLatest(types.CREATE_ACCOUNT, createAccount);
}

export default function* root() {
  yield all([accountActionWatcher()]);
}
