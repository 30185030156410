import React from 'react';
import './index.scss';
import './siicon.scss';
import App from './router/App';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import configureStore from './store/configureStore';

const store = configureStore(),
  container = document.getElementById('root'),
  root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
