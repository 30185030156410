import React from 'react';
import './style.scss';
import Loader from '../../tools/loader';

function ContentSumary({ message, loader, data }) {
  return (
    <div>
      {data ? (
        data.ctaUrl.includes("00000000") ? (
          <div id="htmlSumaryContainer" className="htmlSumaryNotFound">
            <Loader action={'success'} />
            <p className="font20">
              Se envió un correo con las credenciales de acceso a tu cuenta.
            </p>
            <a href='https://siigonube.siigo.com/ISIIGO2/Login.aspx' target='_blank' rel='noreferrer'>
              <img alt='siigo-ingresar' src="https://leafcss.blob.core.windows.net/images/email/aprovAccountant/mail-welcome-send-credentials/Boton-ingresar-software-facturacion-electronica-siigo.png" width="150px"></img>
            </a>
          </div>
        ) : (
          <div id="htmlSumaryContainer" className="htmlSumaryNotFound">
            <a href={data.ctaUrl}>
              <Loader action={'success'} />
            </a>
          </div>
        )) : (
        <div className="htmlSumaryNotFound">
          {loader ? (
            <div>
              <Loader action={'create'} />
            </div>
          ) : (
            <div>
              <i className="siicon icon-info"></i>
              <p className="textInfo">{message}</p>
              <p className="font20">
                Por favor contacta con tu agente comercial
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ContentSumary;
