import * as types from '../actions';

const portalContadorReducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    case types.CREATE_ACCOUNT:
      return { ...state, isLoading: true };
    case types.CREATE_ACCOUNT_RECEIVED:
      return { ...state, isLoading: false, accountData: action.data };
    case types.CREATE_ACCOUNT_FAILED:
      return { ...state, isLoading: false, accountData: action.err };

    default:
      return state;
  }
};

export default portalContadorReducer;
