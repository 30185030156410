import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Account from '../containers/account/index';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Account />} exact />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
