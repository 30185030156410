import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as accountActions from '../../actions/accountAction';
import { bindActionCreators } from 'redux';
import MenuActions from '../menuActions';
import ContentSumary from '../contentSumary';

function Account({ c, actions, error, isLoading, account }) {
  const [state, setState] = useState({ error: false });
  const [newAccount, setNewAccount] = useState({ create: false, error: false });
  const [messageError, setMessageError] = useState('');

  useEffect(() => {
    c ? actions?.createAccount({ c }) : setState({ error: true })
  }, []);

  useEffect(() => {
    if (account) {
      if (account.status) {
        setNewAccount({ ...newAccount, create: true });
      } else {
        setNewAccount({ ...newAccount, error: true });
        setMessageError(account.msg ? account.msg : 'error al crear la cuenta');
      }
    }
  }, [account])

  return (
    <div>
      {error && (
        <h4 className="Error">
          Ha ocurrido un error con tu cotización contactate con tu agente
          comercial
        </h4>
      )}
      <MenuActions />
      {isLoading ? (
        <div>
          <ContentSumary
            message={'isLoading:: ' + isLoading}
            loader={isLoading}
          />
        </div>
      ) : null}
      {newAccount.create ? (
        <div>
          <ContentSumary message="data" data={account} />
        </div>
      ) : null}
      {newAccount.error || state.error ? (
        <div>
          <ContentSumary message={messageError} />
        </div>
      ) : null}
    </div>
  );
}

Account.propTypes = {
  actions: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account.accountData,
  isLoading: state.account.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
