// a library to wrap and simplify api calls
import apisauce from 'apisauce';
import Account from './services/account';
import config from '../config.js';

const create = (baseURL = config.url) => {
  console.log('baseURL', baseURL);
  console.log('process.env:: ', process.env);
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json'
      //Authorization: "bearer " + getCookie("token")
    },
    // 10 second timeout...
    timeout: 1000000
  });
  const account = new Account(api);

  //  return Api
  return {
    setHeader: (key, value) => api.setHeader(key, value),
    removeHeader: key => api.deleteHeader(key),
    account
  };
};

// let's return back our create method as the default.
export default {
  create
};
