import React from 'react';
import './style.scss';

function MenuActions() {
  return (
    <div>
      <div className="actionMenuNotFound">
        <i className="siicon icon-logo-contador logoContent"></i>
      </div>
    </div>
  );
}

export default MenuActions;
