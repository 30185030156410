import React, { useState } from 'react';
import gifCreate from './createAccount.gif';
import gifSuccess from './success.gif';
import './loader.scss';

function Loader({ action }) {
  const [gif] = useState(action === 'create' ? gifCreate : gifSuccess);

  return (
    <div>
      <img className="gifCreate" src={gif} alt="siigo" />
    </div>
  );
}

export default Loader;
