import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/index';
import config from '../config.js';

export function* createAccount(action) {
  const api = API.create();
  console.log('config------> ', config);
  console.log('run createAccount: ', action);
  try {
    /*api.setHeader('userdta', 'stark:iidkju===dk0');
    let auth = yield call(api.authJwt.auth);
    console.log('token ---->', auth);

    api.setHeader('token', auth.data);*/
    const { params } = action;

    let actionOk = yield call(api.account.createAccount, params);
    console.log('actionOk-saga:::', actionOk);
    yield put({
      type: types.CREATE_ACCOUNT_RECEIVED,
      data: actionOk.data,
    });
  } catch (err) {
    yield put({ type: types.CREATE_ACCOUNT_FAILED, err });
  }
}
